// // AddAbsence.js
// import React, { useState } from "react";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import FooterNavigation from "../FooterNavigation";
// import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";

// const AddAbsence = () => {
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     CD_AUSENCIA: "",
//     DT_ADMISSAO: "", // Added DT_ADMISSAO
//     DT_INI: "",
//     DT_FIM: "",
//     TIPO_REGISTO: "",
//     TP_AUSENCIA: "",
//     ESTADO: "",
//     TP_ORIGEM: "",
//     NR_CALENDARIO: "",
//     NR_MINUTOS: "",
//     MIN_TOTAL: "",
//     MIN_NOCT: "",
//     MIN_MEDIO_N_UTIL: "",
//     REGISTO_ANTERIOR: "",
//     REGISTO_POSTERIOR: "",
//     DT_DOC_JUST: "",
//     ID_DOC_JUST: "",
//     OBS: "",
//   });

//   const apiUrl = process.env.REACT_APP_BASE_URL;

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const token = localStorage.getItem("token");
//       await axios.post(`${apiUrl}/api/absences/add-absence`, formData, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       toast.success("Absence added successfully!");
//       navigate("/absenses"); // Navigate back to calendar or main page
//     } catch (error) {
//       console.error("Error adding absence:", error);
//       alert("Failed to add absence");
//     }
//   };

//   return (
//     <>
//       <CommonHeaderWithMenu title="Register a Absense" />
//       <div className="container mx-auto p-4 mb-20">
//         <form onSubmit={handleSubmit} className="space-y-4">
//           <div>
//             <label className="block font-medium">Absence Code</label>
//             <input
//               type="text"
//               name="CD_AUSENCIA"
//               value={formData.CD_AUSENCIA}
//               onChange={handleChange}
//               className="w-full border border-gray-300 p-2 rounded"
//             />
//           </div>
//           <div>
//             <label className="block font-medium">Admission Date</label>
//             <input
//               type="date"
//               name="DT_ADMISSAO"
//               value={formData.DT_ADMISSAO}
//               onChange={handleChange}
//               className="w-full border border-gray-300 p-2 rounded"
//             />
//           </div>
//           <div>
//             <label className="block font-medium">Start Date & Time</label>
//             <input
//               type="datetime-local"
//               name="DT_INI"
//               value={formData.DT_INI}
//               onChange={handleChange}
//               className="w-full border border-gray-300 p-2 rounded"
//             />
//           </div>
//           <div>
//             <label className="block font-medium">End Date & Time</label>
//             <input
//               type="datetime-local"
//               name="DT_FIM"
//               value={formData.DT_FIM}
//               onChange={handleChange}
//               className="w-full border border-gray-300 p-2 rounded"
//             />
//           </div>
//           <div>
//             <label className="block font-medium">Type of Register</label>
//             <input
//               type="text"
//               name="TIPO_REGISTO"
//               value={formData.TIPO_REGISTO}
//               onChange={handleChange}
//               className="w-full border border-gray-300 p-2 rounded"
//             />
//           </div>
//           <div>
//             <label className="block font-medium">Absence Type</label>
//             <input
//               type="text"
//               name="TP_AUSENCIA"
//               value={formData.TP_AUSENCIA}
//               onChange={handleChange}
//               className="w-full border border-gray-300 p-2 rounded"
//             />
//           </div>
//           <div>
//             <label className="block font-medium">Status</label>
//             <input
//               type="text"
//               name="ESTADO"
//               value={formData.ESTADO}
//               onChange={handleChange}
//               className="w-full border border-gray-300 p-2 rounded"
//             />
//           </div>
//           <div>
//             <label className="block font-medium">Origin Type</label>
//             <input
//               type="text"
//               name="TP_ORIGEM"
//               value={formData.TP_ORIGEM}
//               onChange={handleChange}
//               className="w-full border border-gray-300 p-2 rounded"
//             />
//           </div>
//           <div>
//             <label className="block font-medium">Calendar Number</label>
//             <input
//               type="text"
//               name="NR_CALENDARIO"
//               value={formData.NR_CALENDARIO}
//               onChange={handleChange}
//               className="w-full border border-gray-300 p-2 rounded"
//             />
//           </div>
//           <div>
//             <label className="block font-medium">Minutes</label>
//             <input
//               type="number"
//               name="NR_MINUTOS"
//               value={formData.NR_MINUTOS}
//               onChange={handleChange}
//               className="w-full border border-gray-300 p-2 rounded"
//             />
//           </div>
//           <div>
//             <label className="block font-medium">Total Minutes</label>
//             <input
//               type="number"
//               name="MIN_TOTAL"
//               value={formData.MIN_TOTAL}
//               onChange={handleChange}
//               className="w-full border border-gray-300 p-2 rounded"
//             />
//           </div>
//           <div>
//             <label className="block font-medium">Night Minutes</label>
//             <input
//               type="number"
//               name="MIN_NOCT"
//               value={formData.MIN_NOCT}
//               onChange={handleChange}
//               className="w-full border border-gray-300 p-2 rounded"
//             />
//           </div>
//           <div>
//             <label className="block font-medium">
//               Average Non-Util Minutes
//             </label>
//             <input
//               type="number"
//               name="MIN_MEDIO_N_UTIL"
//               value={formData.MIN_MEDIO_N_UTIL}
//               onChange={handleChange}
//               className="w-full border border-gray-300 p-2 rounded"
//             />
//           </div>
//           <div>
//             <label className="block font-medium">Previous Record</label>
//             <input
//               type="text"
//               name="REGISTO_ANTERIOR"
//               value={formData.REGISTO_ANTERIOR}
//               onChange={handleChange}
//               className="w-full border border-gray-300 p-2 rounded"
//             />
//           </div>
//           <div>
//             <label className="block font-medium">Next Record</label>
//             <input
//               type="text"
//               name="REGISTO_POSTERIOR"
//               value={formData.REGISTO_POSTERIOR}
//               onChange={handleChange}
//               className="w-full border border-gray-300 p-2 rounded"
//             />
//           </div>
//           <div>
//             <label className="block font-medium">Document Date</label>
//             <input
//               type="date"
//               name="DT_DOC_JUST"
//               value={formData.DT_DOC_JUST}
//               onChange={handleChange}
//               className="w-full border border-gray-300 p-2 rounded"
//             />
//           </div>
//           <div>
//             <label className="block font-medium">Document ID</label>
//             <input
//               type="text"
//               name="ID_DOC_JUST"
//               value={formData.ID_DOC_JUST}
//               onChange={handleChange}
//               className="w-full border border-gray-300 p-2 rounded"
//             />
//           </div>
//           <div>
//             <label className="block font-medium">Observations</label>
//             <textarea
//               name="OBS"
//               value={formData.OBS}
//               onChange={handleChange}
//               className="w-full border border-gray-300 p-2 rounded"
//             ></textarea>
//           </div>
//           <button
//             type="submit"
//             className="bg-[#8AB53E] text-white px-4 py-2 rounded"
//           >
//             Submit
//           </button>
//         </form>
//       </div>
//       <FooterNavigation />
//     </>
//   );
// };

// export default AddAbsence;
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FooterNavigation from "../FooterNavigation";
import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";
import VacancyForm from "./VacancyForm";
import AdaptabilityForm from "./AdaptabilityForm";

const AddAbsence = () => {
  const [formType, setFormType] = useState("Absence"); // Default form type
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    CD_AUSENCIA: "",
    DT_ADMISSAO: "",
    DT_INI: "",
    DT_FIM: "",
    TTIPO_REGISTO: "Absence",
    TP_AUSENCIA: "",
    ESTADO: "",
    TP_ORIGEM: "",
    NR_CALENDARIO: "",
    NR_MINUTOS: "",
    MIN_TOTAL: "",
    MIN_NOCT: "",
    MIN_MEDIO_N_UTIL: "",
    REGISTO_ANTERIOR: "",
    REGISTO_POSTERIOR: "",
    DT_DOC_JUST: "",
    ID_DOC_JUST: "",
    INCAPACITY: "",
    OBS: "",
    file: null, // For file upload
  });

  // For dynamically populating dropdowns
  const [absenceTypes, setAbsenceTypes] = useState([]);
  const [types, setTypes] = useState([]);

  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    // Populate absence types and types dynamically
    const fetchOptions = () => {
      setAbsenceTypes([
        { value: "001", label: "001-Acidente Percurso" },
        { value: "002", label: "002-Acidente Trabalho" },
        { value: "003", label: "003-Assistência Família (S/ Venc.)" },
        { value: "004", label: "004-Assistência Família (C/ Venc.)" },
        { value: "006", label: "006-Ausente Serviço no Estrangeiro" },
        { value: "008", label: "008-Casamento" },
        { value: "009", label: "009-Falta a Justificar (S/ Venc.)" },
        { value: "010", label: "010-Convocação Organismos Oficiais" },
        { value: "011", label: "011-Doação Sangue" },
        { value: "012", label: "012-Doente Com Baixa" },
        { value: "013", label: "013-Doente (C/ Venc.)" },
        { value: "014", label: "014-Doente (S/ Venc.)" },
        { value: "020", label: "020-Licença Com Vencimento" },
        { value: "021", label: "021-Licença Sem Vencimento" },
        { value: "022", label: "022-Licença Maternidade (S/Venc)" },
        { value: "023", label: "023-Nojo" },
        { value: "024", label: "024-Acidente Trabalho (C/ Venc.)" },
        { value: "028", label: "028-Licença Parental Obrigatória" },
        { value: "029", label: "029-Licença Parental Facultativa" },
        { value: "030", label: "030-Falta Injustificada" },
        { value: "032", label: "032-Suspensão (S/ Venc.)" },
        { value: "033", label: "033-Suspensão (C/ Venc.)" },
        { value: "034", label: "034-Tribunal" },
        { value: "038", label: "038-Ensino" },
        { value: "039", label: "039-Exame" },
        { value: "048", label: "048-Ausente Serviço em Portugal" },
        { value: "049", label: "049-Licença Parental (Partilhada)" },
        { value: "050", label: "050-Falta Justificada Sem Vencimento" },
        { value: "053", label: "053-Outras ausências não remuneradas" },
        { value: "080", label: "080-Baixa por Acidente de Trabalho" },
        { value: "666", label: "666-Baixa Psiquiátrica" },
        { value: "999", label: "999-Ausência para justificar" },
      ]);

      setTypes([
        { value: "Ausência", label: "Ausência" },
        { value: "Vacancy", label: "Vacancy" },
        { value: "Adaptability", label: "Adaptability" },
      ]);
    };

    fetchOptions();
  }, []);

  const handleTypeChange = (e) => {
    setFormType(e.target.value); // Update form type dynamically
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      file: e.target.files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const payload = {
        EMPRESA: "DEMO03", // Example company, replace dynamically if needed
        CD_AUSENCIA: formData.CD_AUSENCIA || "004",
        DT_INI: formData.DT_INI,
        DT_FIM: formData.DT_FIM,
        TIPO_REGISTO: formData.TIPO_REGISTO || "Absence",
        TP_AUSENCIA: formData.TP_AUSENCIA,
        ESTADO: formData.ESTADO || "A", // Default status
        TP_ORIGEM: formData.TP_ORIGEM || "A", // Default origin
        NR_CALENDARIO: formData.NR_CALENDARIO || 1, // Default value
        NR_MINUTOS: formData.NR_MINUTOS || 540, // Example
        MIN_TOTAL: formData.MIN_TOTAL || 540, // Example
        MIN_NOCT: formData.MIN_NOCT || 0,
        MIN_MEDIO_N_UTIL: formData.MIN_MEDIO_N_UTIL || 0,
        REGISTO_ANTERIOR: formData.REGISTO_ANTERIOR || "N",
        REGISTO_POSTERIOR: formData.REGISTO_POSTERIOR || "N",
        ID_DOC_JUST: formData.ID_DOC_JUST || null,
        DT_DOC_JUST: formData.DT_DOC_JUST || null,
        INCAPACITY: formData.INCAPACITY ? Number(formData.INCAPACITY) : null,
        OBS: formData.OBS,
        DT_ADMISSAO: formData.DT_ADMISSAO,
      };
      await axios.post(`${apiUrl}/api/absences/add-absence`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Absence added successfully!");
      navigate("/absenses"); // Navigate back to the main page
    } catch (error) {
      console.error("Error adding absence:", error);
      alert("Failed to add absence");
    }
  };
  const selectedAbsence = absenceTypes.find(
    (absence) => absence.value === formData.TP_AUSENCIA
  );
  // const renderForm = () => {
  //   switch (formData.TIPO_REGISTO) {
  //     case "Vacancy":
  //       return <VacancyForm navigate={navigate} apiUrl={apiUrl}  />;
  //     case "Adaptability":
  //       return <AdaptabilityForm navigate={navigate} apiUrl={apiUrl} />;
  //     default:
  //       return (
  //         <div>
  //           <div className=" mb-10">
  //             {/* Existing Absence Form */}
  //             <div>
  //               <label className="block font-medium">Type</label>
  //               <select
  //                 name="TIPO_REGISTO" // Maps directly to TIPO_REGISTO
  //                 value={formData.TIPO_REGISTO}
  //                 onChange={handleChange}
  //                 className="w-full border border-gray-300 p-2 rounded"
  //               >
  //                 <option value="">Select Type</option>
  //                 {types.map((type) => (
  //                   <option key={type.value} value={type.value}>
  //                     {type.label}
  //                   </option>
  //                 ))}
  //               </select>
  //             </div>
  //             <div>
  //               <label className="block font-medium">Absence</label>
  //               <select
  //                 name="TP_AUSENCIA" // Maps directly to TP_AUSENCIA
  //                 value={formData.TP_AUSENCIA}
  //                 onChange={handleChange}
  //                 className="w-full border border-gray-300 p-2 rounded"
  //               >
  //                 <option value="">Select Absence</option>
  //                 {absenceTypes.map((absence) => (
  //                   <option key={absence.value} value={absence.value}>
  //                     {absence.label}
  //                   </option>
  //                 ))}
  //               </select>
  //             </div>
  //             <div>
  //               <label className="block font-medium">Start Date & Time</label>
  //               <input
  //                 type="datetime-local"
  //                 name="DT_INI"
  //                 value={formData.DT_INI}
  //                 onChange={handleChange}
  //                 className="w-full border border-gray-300 p-2 rounded"
  //               />
  //             </div>
  //             <div>
  //               <label className="block font-medium">End Date & Time</label>
  //               <input
  //                 type="datetime-local"
  //                 name="DT_FIM"
  //                 value={formData.DT_FIM}
  //                 onChange={handleChange}
  //                 className="w-full border border-gray-300 p-2 rounded"
  //               />
  //             </div>
  //             {formData.TP_AUSENCIA === "080" && (
  //               <div>
  //                 <label className="block font-medium">Incapacity</label>
  //                 <input
  //                   type="number"
  //                   name="incapacity"
  //                   value={formData.INCAPACITY}
  //                   onChange={handleChange}
  //                   className="w-full border border-gray-300 p-2 rounded"
  //                 />
  //               </div>
  //             )}

  //             <div>
  //               <label className="block font-medium">Observations</label>
  //               <textarea
  //                 name="OBS"
  //                 value={formData.OBS}
  //                 onChange={handleChange}
  //                 className="w-full border border-gray-300 p-2 rounded"
  //               ></textarea>
  //             </div>
  //             <div>
  //               <label className="block font-medium">Choose File</label>
  //               <input
  //                 type="file"
  //                 name="file"
  //                 onChange={handleFileChange}
  //                 className="w-full border border-gray-300 p-2 rounded"
  //               />
  //             </div>
  //             <div className="flex justify-between gap-3">
  //               <Link to="/absenses">
  //                 <button
  //                   type="submit"
  //                   className="bg-[#b53e3e] text-white px-4 py-2 rounded"
  //                 >
  //                   cancel
  //                 </button>
  //               </Link>
  //               {/* <button
  //               type="submit"
  //               className="bg-[#8AB53E] text-white px-4 py-2 rounded"
  //             >
  //               Submit
  //             </button> */}
  //               <button
  //                 type="submit"
  //                 className="bg-[#8AB53E] text-white px-4 py-2 rounded mt-4"
  //                 onClick={handleSubmit}
  //               >
  //                 Submit Absence
  //               </button>
  //             </div>
  //           </div>
  //         </div>
  //       );
  //   }
  // };
  const renderForm = () => {
    switch (formType) {
      case "Vacancy":
        return <VacancyForm />;

      case "Adaptability":
        return <AdaptabilityForm />;

      default:
        // Absence form as default
        return (
          <form onSubmit={handleSubmit}>
            <div className=" mb-10">
              {/* Existing Absence Form */}
              {/* <div>
                <label className="block font-medium">Type</label>
                <select
                  name="TIPO_REGISTO" // Maps directly to TIPO_REGISTO
                  value={formData.TIPO_REGISTO}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                >
                  <option value="">Select Type</option>
                  {types.map((type) => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </select>
              </div> */}
              <div>
                <label className="block font-medium">Absence</label>
                <select
                  name="TP_AUSENCIA" // Maps directly to TP_AUSENCIA
                  value={formData.TP_AUSENCIA}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                >
                  <option value="">Select Absence</option>
                  {absenceTypes.map((absence) => (
                    <option key={absence.value} value={absence.value}>
                      {absence.label}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block font-medium">Start Date & Time</label>
                <input
                  type="datetime-local"
                  name="DT_INI"
                  value={formData.DT_INI}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
              <div>
                <label className="block font-medium">End Date & Time</label>
                <input
                  type="datetime-local"
                  name="DT_FIM"
                  value={formData.DT_FIM}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
              {formData.TP_AUSENCIA === "080" && (
                <div>
                  <label className="block font-medium">Incapacity</label>
                  <input
                    type="number"
                    name="INCAPACITY" // Updated to match formData key
                    value={formData.INCAPACITY}
                    onChange={handleChange}
                    className="w-full border border-gray-300 p-2 rounded"
                  />
                </div>
              )}

              {/* {selectedAbsence?.requiresIncapacity && (
                <div>
                  <label className="block font-medium">Incapacity</label>
                  <input
                    type="number"
                    name="INCAPACITY"
                    value={formData.INCAPACITY}
                    onChange={handleChange}
                    className="w-full border border-gray-300 p-2 rounded"
                  />
                </div>
              )} */}
              <div>
                <label className="block font-medium">Observations</label>
                <textarea
                  name="OBS"
                  value={formData.OBS}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                ></textarea>
              </div>
              <div>
                <label className="block font-medium">Choose File</label>
                <input
                  type="file"
                  name="file"
                  onChange={handleFileChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
              <div className="flex justify-between gap-3">
                <div className="mt-4">
                  <Link to="/absenses">
                    <button
                      type="submit"
                      className="bg-[#b53e3e] text-white px-4 py-2 rounded"
                    >
                      cancel
                    </button>
                  </Link>
                </div>
                {/* <button
                type="submit"
                className="bg-[#8AB53E] text-white px-4 py-2 rounded"
              >
                Submit
              </button> */}
                <button
                  type="submit"
                  className="bg-[#8AB53E] text-white px-4 py-2 rounded mt-4"
                  onClick={handleSubmit}
                >
                  Submit Absence
                </button>
              </div>
            </div>
          </form>
        );
    }
  };
  return (
    <>
      <CommonHeaderWithMenu title={`Register a ${formType}`} />
      <div className="container mx-auto p-4 mb-20">
        <div>
          <label className="block font-medium">Select Type</label>
          <select
            value={formType}
            onChange={handleTypeChange}
            className="w-full border border-gray-300 p-2 rounded mb-4"
          >
            <option value="Absence">Absence</option>
            <option value="Vacancy">Vacancy</option>
            <option value="Adaptability">Adaptability</option>
          </select>
        </div>
        <div>
          {renderForm()}
          {/*<div>
            <label className="block font-medium">Admission Date</label>
            <input
              type="date"
              name="DT_ADMISSAO"
              value={formData.DT_ADMISSAO}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div> */}
        </div>
      </div>
      <FooterNavigation />
    </>
  );
};

export default AddAbsence;
