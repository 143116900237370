import React, { useEffect, useState } from "react";
import {
  FaAddressCard,
  FaChartLine,
  FaCoins,
  FaFileAlt,
  FaHome,
  FaHourglassStart,
  FaPaperPlane,
  FaTimes,
} from "react-icons/fa";
import { FaCircleCheck, FaDochub, FaSquareRss } from "react-icons/fa6";
import { FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // Import jwt-decode correctly
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { Badge, Dropdown, List, Avatar } from "antd";

import { BellOutlined } from "@ant-design/icons";
import { NotificationContext } from "../../context/NotificationProvider";

const CommonHeaderWithMenu = ({ title }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [rhid, setRhid] = useState(null); // State for RHID
  const [menuItems, setMenuItems] = useState([]); // State for menu items
  const [idPerfDef, setIdPerfDef] = useState(null); // State for user role
  // ID_PERF_DEF idPerfDef
  const { t } = useTranslation();
  const { notifications, unreadCount, setUnreadCount, removeNotification } =
    useContext(NotificationContext);

  const handleNotificationClick = () => {
    setUnreadCount(0); // Reset unread count when the dropdown is opened
  };
  const handleDeleteNotification = (notificationId) => {
    removeNotification(notificationId);
  };

  // const notificationMenu = (
  //   <List
  //     className="bg-[#8AB53E] text-white w-48"
  //     size="small"
  //     dataSource={notifications}
  //     renderItem={(item) => (
  //       <List.Item>
  //         <List.Item.Meta
  //           avatar={
  //             <Avatar src={item.IMAGE_URL || "/default-notification.png"} />
  //           }
  //           title={item.SUBJECT}
  //           description={item.BODY}
  //         />
  //       </List.Item>
  //     )}
  //   />
  // );
  const notificationMenu = (
    <div className="bg-white rounded-lg shadow-lg max-h-72 w-48 overflow-y-auto">
      <List
        size="small"
        dataSource={notifications}
        renderItem={(item) => (
          <List.Item
            actions={[
              <span
                className="text-red-500 cursor-pointer"
                onClick={() => handleDeleteNotification(item.ID_NOTIF)}
              >
                Delete
              </span>,
            ]}
          >
            <List.Item.Meta
              avatar={
                <Avatar src={item.IMAGE_URL || "/default-notification.png"} />
              }
              title={<span className="font-semibold">{item.SUBJECT}</span>}
              description={<span className="text-sm">{item.BODY}</span>}
            />
          </List.Item>
        )}
      />
    </div>
  );

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Decode JWT to get user details
  useEffect(() => {
    const fetchUserData = () => {
      const token = localStorage.getItem("token"); // Adjust the key if necessary
      if (token) {
        const decodedToken = jwtDecode(token);
        // console.log(decodedToken); // Log the decoded token
        const userRhid = decodedToken.RHID; // Assuming your token has 'rhid'
        const role = decodedToken.ID_PERF_DEF; // Assuming your token has 'role'
        console.log(role);
        setRhid(userRhid);
        setIdPerfDef(role);

        // Set menu items based on role
        const menuData = {
          admin: [
            { icon: <FaHome />, label: t("Home"), link: "/employee" },
            { icon: <FaAddressCard />, label: t("Cadastre"), link: "/cadstre" },
            { icon: <FaFileAlt />, label: t("Outputs"), link: "/outputs" },
            {
              icon: <FaHourglassStart />,
              label: t("Time Management"),
              link: "/time-management",
            },
            {
              icon: <FaCircleCheck />,
              label: t("Approvals"),
              link: "/admin-approval",
            },
            {
              icon: <FaPaperPlane />,
              label: t("Delegations"),
              link: "/admin-delegations",
            },
            {
              icon: <FaSquareRss />,
              label: t("Communication"),
              link: "/communication",
            },
            {
              icon: <FaChartLine />,
              label: t("KPIs"),
              link: "/admin-kpis",
            },
          ],
          employee: [
            { icon: <FaCoins />, label: t("Expenses"), link: "/expanses" },
            { icon: <FaAddressCard />, label: t("Cadastre"), link: "/cadstre" },
            { icon: <FaFileAlt />, label: t("Outputs"), link: "/outputs" },
            {
              icon: <FaSquareRss />,
              label: t("Communication"),
              link: "/communication",
            },
            {
              icon: <FaHourglassStart />,
              label: t("Time Management"),
              link: "/time-management",
            },
            {
              icon: <FaDochub />,
              label: "Doc.Request",
              link: "/communication",
            },
          ],
        };

        // setMenuItems(menuData[role] || []);
        // console.log(menuData[role]);
        // Log the menu items based on role
        if (role === 7) {
          setMenuItems(menuData.admin); // Admin menu
        } else {
          setMenuItems(menuData.employee); // Employee menu
        }
      }
    };

    fetchUserData();
  }, []);

  return (
    <>
      <div
        className={`${
          isSticky ? "fixed top-0 left-0 right-0 z-50 shadow-md" : ""
        } bg-white p-4 transition-all duration-300 ease-in-out`}
        style={isSticky ? { marginBottom: "64px" } : {}} // Adjust margin for sticky header
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center  space-x-4">
            {/* Hamburger Menu */}
            <div
              className="flex items-center justify-center w-10 h-10 bg-transparent cursor-pointer"
              onClick={toggleMenu}
            >
              <FiMenu className="text-xl text-gray-600" />
            </div>
          </div>

          <h1 className="text-xl font-semibold text-[#373737]">{title}</h1>

          <div className="relative flex items-center justify-center">
            <h1 className="text-white ml-[20px]">.</h1>
          </div>
          {/* Notification Icon */}
          {idPerfDef !== 7 && (
            <>
              <div>
                <Dropdown
                  overlay={notificationMenu}
                  trigger={["click"]}
                  onClick={handleNotificationClick}
                >
                  <Badge count={unreadCount}>
                    <BellOutlined style={{ fontSize: 24, cursor: "pointer" }} />
                  </Badge>
                </Dropdown>
              </div>
            </>
          )}
        </div>
      </div>

      {/* Menu */}
      {menuOpen && (
        <div
          style={{
            transform: menuOpen ? "translateX(0)" : "translateX(-100%)",
            transition: "transform 0.3s ease",
          }}
          className="fixed inset-y-0 left-0 w-3/4 max-w-xs bg-gradient-to-b
           bg-[#FFFFFF] text-black shadow-lg z-50 p-6 rounded-tr-lg rounded-br-lg overflow-y-auto"
        >
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold text-black">
              {idPerfDef === 7 ? "Admin" : "Employee"}
            </h2>
            <FaTimes className="text-xl text-black" onClick={toggleMenu} />
          </div>
          <ul className="space-y-6">
            {menuItems.map((item, index) => (
              <li key={index} className="flex items-center">
                {item.icon} {/* Assuming the icon is a component */}
                <Link to={item.link} className="text-black text-lg ml-3">
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Overlay */}
      {menuOpen && (
        <div
          className="fixed inset-0 bg-gray-800 bg-opacity-75 z-40"
          onClick={toggleMenu}
        ></div>
      )}
    </>
  );
};

export default CommonHeaderWithMenu;
