// import React, { useState } from "react";
// import axios from "axios";
// import { toast } from "react-toastify";

// const VacancyForm = ({ navigate, apiUrl, formData, types }) => {
//   const [vacancyData, setVacancyData] = useState({
//     empresa: "",
//     dtAdmissao: "",
//     dtIni: "",
//     dtFim: "",
//     cdRubrica: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setVacancyData((prevData) => ({ ...prevData, [name]: value }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const token = localStorage.getItem("token");
//       await axios.post(`${apiUrl}/api/vacancies`, vacancyData, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       toast.success("Vacancy created successfully!");
//       navigate("/vacancies");
//     } catch (error) {
//       console.error("Error creating vacancy:", error);
//       toast.error("Failed to create vacancy.");
//     }
//   };

//   return (
//     <div>
//       <div>
//         <label className="block font-medium">Type</label>
//         <select
//           name="TIPO_REGISTO" // Maps directly to TIPO_REGISTO
//           value={formData.TIPO_REGISTO}
//           onChange={handleChange}
//           className="w-full border border-gray-300 p-2 rounded"
//         >
//           <option value="">Select Type</option>
//           {types.map((type) => (
//             <option key={type.value} value={type.value}>
//               {type.label}
//             </option>
//           ))}
//         </select>
//       </div>
//       <div>
//         <label className="block font-medium">Company</label>
//         <input
//           type="text"
//           name="empresa"
//           value={vacancyData.empresa}
//           onChange={handleChange}
//           className="w-full border border-gray-300 p-2 rounded"
//         />
//       </div>
//       <div>
//         <label className="block font-medium">Start Date & Time</label>
//         <input
//           type="datetime-local"
//           name="dtIni"
//           value={vacancyData.dtIni}
//           onChange={handleChange}
//           className="w-full border border-gray-300 p-2 rounded"
//         />
//       </div>
//       {/* Additional fields for Vacancy */}
//       <button
//         type="button"
//         className="bg-[#8AB53E] text-white px-4 py-2 rounded mt-4"
//         onClick={handleSubmit}
//       >
//         Submit Vacancy
//       </button>
//     </div>
//   );
// };

// export default VacancyForm;

import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const VacancyForm = () => {
  const [formData, setFormData] = useState({
    EMPRESA: "",
    DT_ADMISSAO: "",
    DT_INI: "",
    DT_FIM: "",
    CD_RUBRICA: "", // Ensure all fields are initialized
  });

  const apiUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const [existingEmpresas, setExistingEmpresas] = useState([]);
//   console.log(existingEmpresas);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    // Fetch existing EMPRESA values from the backend
    const fetchExistingEmpresas = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/admin/vacancies/empresas`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // Assuming response.data contains an array of EMPRESA values
        setExistingEmpresas(response.data);
      } catch (error) {
        console.error("Error fetching existing EMPRESA values:", error);
      }
    };

    fetchExistingEmpresas();
  }, []);

  const handleValidateEmpresa = () => {
    if (
      existingEmpresas.some(
        (empresa) =>
          empresa.trim().toLowerCase() === formData.EMPRESA.trim().toLowerCase()
      )
    ) {
      alert("EMPRESA must be unique. Please provide a different value.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!handleValidateEmpresa()) {
      return; // Stop form submission if EMPRESA is not unique
    }

    try {
      const token = localStorage.getItem("token");
      const payload = {
        empresa: formData.EMPRESA.trim(),
        dtAdmissao: formData.DT_ADMISSAO,
        dtIni: formData.DT_INI,
        dtFim: formData.DT_FIM,
        cdRubrica: formData.CD_RUBRICA || "Default",
        estado: "D",
        ANO: new Date().getFullYear(),
      };

      console.log("Submitting payload:", payload);
      await axios.post(`${apiUrl}/api/admin/vacancies`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success("Vacancy added successfully!");
      navigate("/absenses");
    } catch (error) {
      console.error("Error response:", error.response?.data || error);
      toast.error(error.response?.data?.error || "Failed to add vacancy.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3 className="font-medium mb-4">Vacancy Details</h3>
      <div>
        <label className="block font-medium">EMPRESA</label>
        <input
          type="text"
          name="EMPRESA" // Use uppercase to match formData key
          value={formData.EMPRESA}
          onChange={handleChange}
          className="w-full border border-gray-300 p-2 rounded"
        />
      </div>
      <div>
        <label className="block font-medium">Admission Date</label>
        <input
          type="date"
          name="DT_ADMISSAO"
          value={formData.DT_ADMISSAO}
          onChange={handleChange}
          className="w-full border border-gray-300 p-2 rounded"
        />
      </div>
      <div>
        <label className="block font-medium">Start Date & Time</label>
        <input
          type="datetime-local"
          name="DT_INI"
          value={formData.DT_INI}
          onChange={handleChange}
          className="w-full border border-gray-300 p-2 rounded"
        />
      </div>
      <div>
        <label className="block font-medium">End Date & Time</label>
        <input
          type="datetime-local"
          name="DT_FIM"
          value={formData.DT_FIM}
          onChange={handleChange}
          className="w-full border border-gray-300 p-2 rounded"
        />
      </div>
      <div>
        <label className="block font-medium">Rubrica Code</label>
        <input
          type="text"
          name="CD_RUBRICA"
          value={formData.CD_RUBRICA}
          onChange={handleChange}
          className="w-full border border-gray-300 p-2 rounded"
        />
      </div>
      <div className="flex justify-between gap-3">
        <div className="mt-4">
          <Link to="/absenses">
            <button
              type="submit"
              className="bg-[#b53e3e] text-white px-4 py-2 rounded"
            >
              cancel
            </button>
          </Link>
        </div>
        <button
          type="submit"
          className="bg-[#8AB53E] text-white px-4 py-2 rounded mt-4"
        >
          Submit Vacancy
        </button>
      </div>
    </form>
  );
};

export default VacancyForm;
