// import React, { createContext, useState, useEffect } from "react";
// import { jwtDecode } from "jwt-decode";

// export const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   useEffect(() => {
//     const checkTokenExpiration = () => {
//       const token = localStorage.getItem("token");

//       if (token) {
//         try {
//           const decoded = jwtDecode(token);
//           const isExpired = decoded.exp * 1000 < Date.now(); // Check if token is expired

//           if (isExpired) {
//             setIsAuthenticated(false);
//             localStorage.removeItem("token"); // Remove the expired token
//           } else {
//             setIsAuthenticated(true);
//           }
//         } catch (error) {
//           setIsAuthenticated(false);
//         }
//       } else {
//         setIsAuthenticated(false);
//       }
//     };

//     // Initial check
//     checkTokenExpiration();

//     // Periodic check (every 30 seconds)
//     const intervalId = setInterval(checkTokenExpiration, 30000);

//     return () => clearInterval(intervalId); // Cleanup interval on unmount
//   }, []);

//   return (
//     <AuthContext.Provider value={{ isAuthenticated }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };
import React, { createContext, useState, useEffect, useMemo } from "react";
import { jwtDecode } from "jwt-decode";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(undefined);

  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = localStorage.getItem("token");

      if (token) {
        try {
          const decoded = jwtDecode(token);
          const isExpired = decoded.exp * 1000 < Date.now(); // Check if token is expired

          if (isExpired) {
            setIsAuthenticated(false);
            localStorage.removeItem("token"); // Remove the expired token
          } else {
            setIsAuthenticated(true);
          }
        } catch (error) {
          setIsAuthenticated(false);
        }
      } else {
        setIsAuthenticated(false);
      }
    };

    // Initial check
    checkTokenExpiration();

    // Periodic check (every 30 seconds)
    // const intervalId = setInterval(checkTokenExpiration, 30000);
    const intervalId = setInterval(checkTokenExpiration, 100);

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, []);
  const authContextValue = useMemo(
    () => ({ isAuthenticated, setIsAuthenticated }),
    [isAuthenticated]
  );

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};
