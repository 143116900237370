import { createBrowserRouter, Link } from "react-router-dom";
import App from "../App";
import Login from "../shared/Login";
import Signup from "../shared/Signup";
import Employee from "../pages/employee/Employee";
import Notification from "../pages/notifications/Notification";
import Tickets from "../pages/tickets/Tickets";
import NewTickets from "../pages/tickets/NewTickets";
import ETicketDetails from "../pages/tickets/ETicketDetails";
import ClockMarking from "../pages/absenses/ClockMarking";
import Absences from "../pages/absenses/Absences";
import Expenses from "../pages/expenses/Expenses";
import Settings from "../component/settings/Settings";
import AddExpense from "../pages/expenses/AddExpense";
import Communication from "../pages/communication/Communication";
import Cadastre from "../pages/cadastre/Cadastre";
import Outputs from "../pages/outputs/Outputs";
import UpdateExpenseDetails from "../pages/expenses/UpdateExpenseDetails";
import UpdateExpenseForm from "../pages/expenses/UpdateExpenseForm";
import { elements } from "chart.js";
import Profile from "./../pages/settings/profile";
import ChangePassword from "./../pages/settings/password";
import AddAbsence from "../pages/absenses/AddAbsence";
import Approval from "../pages/approval/Approval";
import Delegations from "../pages/delegations/Delegations";
import KPIS from "../pages/kpis/KPIS";
import EmployeeDetails from "../pages/cadastre/admin/EmployeeDetails";
import ProtectedRoute from "../utils/ProtectedRoute";
import AdminCommunication from "../pages/communication/admin/AdminCommunication";
import AdaptibilityVaccencyAndAbsenseAll from "../pages/absenses/admin/AdaptibilityVaccencyAndAbsenseAll";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/sign-up",
    element: <Signup />,
  },
  {
    path: "/employee",
    element: (
      <ProtectedRoute>
        <Employee />
      </ProtectedRoute>
    ),
  },
  {
    path: "/notification",
    element: (
      <ProtectedRoute>
        <Notification />
      </ProtectedRoute>
    ),
  },
  {
    path: "/tickets",
    element: (
      <ProtectedRoute>
        <Tickets />
      </ProtectedRoute>
    ),
  },
  {
    path: "/new-tickets",
    element: (
      <ProtectedRoute>
        <NewTickets />
      </ProtectedRoute>
    ),
  },
  {
    path: "/e-ticketsdetails/:ticketId", // Ensure this dynamic route is correctly defined
    element: (
      <ProtectedRoute>
        <ETicketDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: "/absense-clockmarking",
    element: <ClockMarking />,
  },
  {
    path: "/absenses",
    element: (
      <ProtectedRoute>
        <Absences />
      </ProtectedRoute>
    ),
  },
  {
    path: "/add-absence",
    element: (
      <ProtectedRoute>
        <AddAbsence />
      </ProtectedRoute>
    ),
  },
  {
    path: "/expanses",
    element: (
      <ProtectedRoute>
        <Expenses />
      </ProtectedRoute>
    ),
  },
  {
    path: "/expenses/new",
    element: (
      <ProtectedRoute>
        <AddExpense />
      </ProtectedRoute>
    ),
  },
  {
    path: "/expenses/:id",
    element: (
      <ProtectedRoute>
        <UpdateExpenseDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: "/expense/:id/update",
    element: (
      <ProtectedRoute>
        <UpdateExpenseForm />
      </ProtectedRoute>
    ),
  },
  {
    path: "/settings",
    element: (
      <ProtectedRoute>
        <Settings />
      </ProtectedRoute>
    ),
  },
  {
    path: "settings/profile",
    element: (
      <ProtectedRoute>
        <Profile />
      </ProtectedRoute>
    ),
  },
  {
    path: "change-password",
    element: (
      <ProtectedRoute>
        <ChangePassword />
      </ProtectedRoute>
    ),
  },
  {
    path: "/communication",
    element: (
      <ProtectedRoute>
        <Communication />
      </ProtectedRoute>
    ),
  },
  {
    path: "/time-management",
    element: (
      <ProtectedRoute>
        <AdminCommunication />
      </ProtectedRoute>
    ),
  },
  {
    path: "/cadstre",
    element: (
      <ProtectedRoute>
        <Cadastre />
      </ProtectedRoute>
    ),
  },
  {
    path: "/employee/:rhid", // Dynamic route with employee ID
    element: (
      <ProtectedRoute>
        <EmployeeDetails />
      </ProtectedRoute>
    ), // This should be your component that shows employee details
  },

  {
    path: "/outputs",
    element: (
      <ProtectedRoute>
        <Outputs />
      </ProtectedRoute>
    ),
  },
  {
    path: "/admin-approval",
    element: (
      <ProtectedRoute>
        <Approval />
      </ProtectedRoute>
    ),
  },
  {
    path: "/admin-delegations",
    element: (
      <ProtectedRoute>
        <Delegations />
      </ProtectedRoute>
    ),
  },
  {
    path: "/admin-kpis",
    element: (
      <ProtectedRoute>
        <KPIS />
      </ProtectedRoute>
    ),
  },
  {
    path: "/admin-view-adaptibility-vaccency-absense-all",
    element: (
      <ProtectedRoute>
        <AdaptibilityVaccencyAndAbsenseAll />
      </ProtectedRoute>
    ),
  },
  {
    path: "*",
    element: (
      <>
        <p className="text-center text-5xl text-red-500 font-bold flex mx-auto mt-20">
          404: Page Not Found
        </p>
        <Link to="/employee" className="text-center font-bold ml-40 ">
          <u>Go Back</u>
        </Link>
      </>
    ),
  },
]);
export default router;
