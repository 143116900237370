// import React, { useEffect, useState } from "react";
// import FooterNavigation from "../FooterNavigation";
// import { FiMenu, FiMoreVertical } from "react-icons/fi";
// import search from "../../assets/images/search-normal.png";
// import menuLeft from "../../assets/images/menu-left.png";
// import unlock from "../../assets/images/unlock.png";
// import notificationusers from "../../assets/images/users.png";
// import speaker from "../../assets/images/speaker.png";
// import ellapseNotification from "../../assets/images/EllipseNotification.png";
// import staveNotification from "../../assets/images/Stavenotification.png";
// import playCircle from "../../assets/images/play-cricle.png";
// import { FaMagnifyingGlass } from "react-icons/fa6";
// import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";
// import { useTranslation } from "react-i18next";

// // const notifications = [
// //   {
// //     icon: "profile",
// //     title: "You update your profile picture",
// //     description: "You just update your profile picture.",
// //     time: "Just Now",
// //   },
// //   {
// //     icon: "password",
// //     title: "Password Changed",
// //     description: "You’re completed change the password.",
// //     time: "April 12, 2023 at 22:22 PM",
// //   },
// //   {
// //     icon: "leave",
// //     title: "Mark Applied for Leave",
// //     description: "Please accept my leave request.",
// //     time: "February 23, 2023 at 21:22 PM",
// //     image: `${ellapseNotification}`, // Replace with an actual path to the image
// //   },
// //   {
// //     icon: "system",
// //     title: "System Update",
// //     description: "Please update to newest app, for get amazing experience.",
// //     time: "Just Now",
// //   },
// //   //   {
// //   //     icon: "video",
// //   //     title: "Stave share video",
// //   //     description: "You just update your profile picture.",
// //   //     time: "Just Now",
// //   //     image: `${ellapseNotification}`, // Replace with an actual path to the video thumbnail
// //   //   },
// // ];

// const iconMap = {
//   profile: notificationusers,
//   password: unlock,
//   //   leave: leaveIcon,
//   system: speaker,
//   //   video: videoIcon,
// };
// const Notification = () => {
//   const [notifications, setNotifications] = useState([]);
//   console.log(notifications);
//   const apiUrl = process.env.REACT_APP_BASE_URL;
//   const { t } = useTranslation();
//   useEffect(() => {
//     const fetchNotifications = async () => {
//       try {
//         const token = localStorage.getItem("token"); // Get the token from localStorage
//         if (!token) {
//           console.error("No token found");
//           return;
//         }

//         const response = await fetch(`${apiUrl}/api/notifications`, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });

//         if (response.ok) {
//           const data = await response.json();
//           if (data.notifications && data.notifications.length > 0) {
//             setNotifications(data.notifications); // Set notifications if they exist
//           } else {
//             setNotifications([]); // Set an empty array if no notifications are found
//           }
//         } else {
//           const errorText = await response.text(); // Capture the error response from the server
//           console.error("Failed to fetch notifications:", errorText);
//           setNotifications([]); // Set an empty array to handle error cases gracefully
//         }
//       } catch (error) {
//         console.error("Error fetching notifications:", error);
//         setNotifications([]); // Set an empty array in case of an error
//       }
//     };

//     fetchNotifications();
//   }, [apiUrl]);

//   return (
//     <div>
//       <CommonHeaderWithMenu title={t("Notification")} />
//       <div className=" bg-tranparent  rounded-b-lg ">
//         {/* Top Section with Icons and Title */}
//         <div className="flex items-center justify-between mb-4">
//           {/* Menu Icon */}
//           {/* <div
//             className="flex items-center justify-center w-10 h-10
//          bg-transparent"
//           >
//             <img src={menuLeft} alt="" />
//           </div> */}

//           {/* Title */}
//           {/* <h1 className="text-xl font-semibold text-[#373737]">Notification</h1> */}

//           {/* Notification Icon */}
//           {/* <div
//             className="relative flex items-center justify-center
//            "
//           > */}
//           {/* <FaMagnifyingGlass  className="mr-2 size-6"/> */}

//           {/* <img src={search} alt="" className="h-7 w-7 mr-3 " /> */}
//           {/* <FiBell className="text-xl text-gray-600" />
//           <span className="absolute top-1 right-1 w-2.5 h-2.5 bg-green-400 rounded-full"></span> */}
//           {/* </div> */}
//         </div>

//         {/* Search Bar */}
//       </div>
//       <div className="p-4 mb-10">
//         {notifications.length > 0 ? (
//           notifications.map((notification, index) => (
//             <div key={index} className="mb-6">
//               <div className="flex items-start justify-between">
//                 <div className="flex items-start">
//                   {/* Icon or Image */}
//                   {notification.image ? (
//                     <img
//                       src={notification.image}
//                       alt="Notification Thumbnail"
//                       className="w-12 h-12 rounded-full object-cover"
//                     />
//                   ) : (
//                     <div
//                       className="w-12 h-12 flex items-center justify-center rounded-full
//                      bg-[#F1F6E7]"
//                     >
//                       <img
//                         src={iconMap[notification.icon]}
//                         alt={`${notification.icon} icon`}
//                         className="w-6 h-6"
//                       />
//                     </div>
//                   )}
//                 </div>

//                 <div className="flex-1 ml-4">
//                   <h3 className="text-gray-800 font-semibold text-sm">
//                     {notification.title}
//                   </h3>
//                   <p className="text-gray-500 text-xs">
//                     {notification.description}
//                   </p>
//                   <p className="text-[#8AB53E] text-xs mt-1">
//                     {notification.time}
//                   </p>
//                 </div>

//                 <div className="ml-4">
//                   <FiMoreVertical className="text-gray-400" />
//                 </div>
//               </div>

//               {/* Horizontal Line */}
//               {index < notifications.length - 1 && (
//                 <hr className="mt-4 border-[#D9D9D9]" />
//               )}
//             </div>
//           ))
//         ) : (
//           <div className="text-center text-red-500 font-semibold">
//             {t("No notifications available")}.
//           </div>
//         )}

//         {/* <hr className="mb-3 border-[#D9D9D9]" /> */}
//         {/* <div className="flex items-center justify-between mb-6">
//           <div className="flex items-center"> */}
//         {/* Left Icon */}
//         {/* <div className="w-12 h-12 flex items-center justify-center rounded-full bg-[#F1F6E7]">
//               <img
//                 src={notificationusers}
//                 alt="User Icon"
//                 className="w-6 h-6"
//               />
//             </div>

//             <div className="ml-4">
//               <h3 className="text-gray-800 font-semibold text-sm">
//                 Stave share video
//               </h3>
//               <p className="text-gray-500 text-xs">
//                 You just update your profile picture.
//               </p>
//               <p className="text-[#8AB53E] text-xs mt-1">Just Now</p>
//             </div>
//           </div> */}

//         {/* Right Thumbnail with Play Icon */}
//         {/* <div className="relative w-12 h-12 rounded-md overflow-hidden flex-shrink-0 ml-2">
//             <img
//               src={staveNotification}
//               alt="Video Thumbnail"
//               className="object-cover w-full h-full"
//             />
//             <div className="absolute inset-0 flex items-center justify-center">
//               <img src={playCircle} alt="Play Icon" className="w-6 h-6" />
//             </div>
//           </div>
//           <div className="ml-4">
//             <FiMoreVertical className="text-gray-400" />
//           </div>
//         </div>
//         <hr className="mb-3 border-[#D9D9D9]" /> */}
//         {/* Password Changed Section */}
//         {/* <div className="flex items-center justify-between mb-10">
//           <div className="flex items-center"> */}
//         {/* Left Icon */}
//         {/* <div className="w-12 h-12 flex items-center justify-center rounded-full bg-[#F1F6E7]">
//               <img src={unlock} alt="Unlock Icon" className="w-6 h-6" />
//             </div>

//             <div className="ml-4">
//               <h3 className="text-gray-800 font-semibold text-sm">
//                 Password Changed
//               </h3>
//               <p className="text-gray-500 text-xs">
//                 You’re completed change the password.
//               </p>
//               <p className="text-[#8AB53E] text-xs mt-1">
//                 April 12, 2023 at 22:22 PM
//               </p>
//             </div>
//           </div> */}

//         {/* More Options Icon */}
//         {/* <div className="ml-4">
//             <FiMoreVertical className="text-gray-400" />
//           </div> */}
//         {/* </div> */}
//       </div>
//       <FooterNavigation />
//     </div>
//   );
// };

// export default Notification;

import React, { useEffect, useState } from "react";
import FooterNavigation from "../FooterNavigation";
import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";
import { FiMoreVertical } from "react-icons/fi";
import { Modal, Button, Input, Select } from "antd"; // Using Ant Design for UI components
import notificationusers from "../../assets/images/users.png";
import unlock from "../../assets/images/unlock.png";
import speaker from "../../assets/images/speaker.png";
import { useTranslation } from "react-i18next";
import { io } from "socket.io-client";
import { Option } from "antd/es/mentions";
import { jwtDecode } from "jwt-decode"; // Make sure to install jwt-decode if not already installed: npm install jwt-decode
import { notification as AntdNotification } from "antd";
import axios from "axios";

const iconMap = {
  "general test": notificationusers,
  "password changed": unlock,
  "meeting reminder": speaker,
};

// // Extract RHID from token
// const token = localStorage.getItem("token");
// let rhid = null;

// if (token) {
//   try {
//     const decodedToken = jwtDecode(token); // Decode the JWT
//     rhid = decodedToken?.RHID; // Extract RHID from the decoded token
//   } catch (error) {
//     console.error("Failed to decode token:", error);
//   }
// }
// // Extract RHID from localStorage
// const userInfo = JSON.parse(localStorage.getItem("userInfo"));
// // const rhid = userInfo?.RHID;
// const apiUrl = process.env.REACT_APP_BASE_URL;

// // Initialize Socket.IO with the RHID
// const socket = io(`${apiUrl}`, {
//   query: { rhid }, // Pass RHID as a query parameter
// });

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState(null); // For modal
  // console.log(selectedNotification);
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal state
  const [originalNotification, setOriginalNotification] = useState(null);
  const { t } = useTranslation();
  const [imageFile, setImageFile] = useState(null); // For storing the image file
  const imgbbApiKey = process.env.REACT_APP_IMGBB_API_KEY;
  // console.log(imgbbApiKey);

  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [newNotification, setNewNotification] = useState({
    type: "",
    subject: "",
    body: "",
    cd_lingua: 1,
    id_inq: null,
    image_url: "",
    target_date: "",
    gender_filter: "",
    age_min: null,
    age_max: null,
    city_filter: "",
    company_filter: "",
    target_users: [],
  });

  console.log(newNotification);

  // useEffect(() => {
  //   const fetchNotifications = async () => {
  //     try {
  //       const token = localStorage.getItem("token");
  //       if (!token) {
  //         console.error("No token found");
  //         return;
  //       }

  //       const response = await fetch(`${apiUrl}/api/notifications`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });

  //       if (response.ok) {
  //         const data = await response.json();
  //         setNotifications(data.notifications || []);
  //       } else {
  //         console.error(
  //           "Failed to fetch notifications:",
  //           await response.text()
  //         );
  //       }
  //     } catch (error) {
  //       console.error("Error fetching notifications:", error);
  //     }
  //   };

  //   fetchNotifications();
  //   socket.on("new-notification", (notification) => {
  //     setNotifications((prev) => [notification, ...prev]);
  //   });

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, [apiUrl]);
  // const handleCreateNotification = async () => {
  //   try {
  //     const token = localStorage.getItem("token");
  //     if (!token) {
  //       alert("Please log in to create a notification.");
  //       return;
  //     }

  //     // Include RHID from userInfo in the request
  //     const response = await fetch(`${apiUrl}/api/push-notifications`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: JSON.stringify(newNotification),
  //     });

  //     if (response.ok) {
  //       alert("Notification created and pushed successfully!");
  //       setIsCreateModalVisible(false);
  //       setNewNotification({
  //         type: "",
  //         subject: "",
  //         body: "",
  //         cd_lingua: 1,
  //         id_inq: null,
  //         image_url: "",
  //         target_date: "",
  //         gender_filter: "",
  //         age_min: null,
  //         age_max: null,
  //         city_filter: "",
  //         company_filter: "",
  //         target_users: [],
  //       });
  //     } else {
  //       console.error("Failed to create notification:", await response.text());
  //     }
  //   } catch (error) {
  //     console.error("Error creating notification:", error);
  //   }
  // };

  // useEffect(() => {
  //   const fetchNotifications = async () => {
  //     const token = localStorage.getItem("token");
  //     const response = await fetch(`${apiUrl}/api/notifications`, {
  //       headers: { Authorization: `Bearer ${token}` },
  //     });

  //     if (response.ok) {
  //       const data = await response.json();
  //       setNotifications(data.notifications || []);
  //     } else {
  //       console.error("Failed to fetch notifications:", await response.text());
  //     }
  //   };

  //   fetchNotifications();

  //   socket.on("new-notification", (notification) => {
  //     setNotifications((prev) => [notification, ...prev]);
  //   });

  //   return () => socket.disconnect();
  // }, [apiUrl]);
  const [rhid, setRhid] = useState(null); // Dynamically store the RHID
  console.log(rhid);

  const apiUrl = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setRhid(decodedToken?.RHID); // Dynamically set the RHID
      } catch (error) {
        console.error("Failed to decode token:", error);
      }
    }
  }, []);
  // Initialize Socket.IO with the RHID
  useEffect(() => {
    const socket = io(apiUrl, {
      query: { rhid }, // Pass RHID dynamically to the backend
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 5000,
      reconnectionDelayMax: 30000,
      timeout: 24 * 60 * 60 * 1000, // 1 day timeout
    });

    socket.on("connect", () => {
      console.log("WebSocket connected:", socket.id);
    });

    socket.on("new-notification", (notification) => {
      console.log("Real-time notification received:", notification);

      // Show popup notification
      AntdNotification.open({
        message: notification.SUBJECT || "New Notification",
        description: notification.BODY || "You have a new notification.",
        icon: (
          <img
            src={notification.IMAGE_URL || speaker}
            alt="icon"
            style={{ width: 24, height: 24 }}
          />
        ),
        duration: 15, // Close after 5 seconds
        style: { backgroundColor: "#f6ffed", border: "1px solid #b7eb8f" },
      });

      // Update the notifications list
      setNotifications((prev) => [
        {
          ID_NOTIF: notification.ID_NOTIF,
          TYPE: notification.TYPE,
          SUBJECT: notification.SUBJECT,
          BODY: notification.BODY,
          IMAGE_URL: notification.IMAGE_URL,
          DT_INSERTED: notification.DT_INSERTED,
        },
        ...prev,
      ]);
    });

    socket.on("disconnect", () => {
      console.log("WebSocket disconnected:", socket.id);
    });

    return () => {
      console.log("Cleaning up WebSocket connection...");
      socket.disconnect();
    };
  }, [apiUrl, rhid]);

  const fetchAdminNotifications = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/api/admin/notifications`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Admin notifications:", data.notifications);
        setNotifications(data.notifications || []);
      } else {
        console.error(
          "Failed to fetch admin notifications:",
          await response.text()
        );
      }
    } catch (error) {
      console.error("Error fetching admin notifications:", error);
    }
  };

  const fetchNotifications = async () => {
    const token = localStorage.getItem("token");
    if (!token) return;

    try {
      const response = await fetch(`${apiUrl}/api/notifications`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Fetched notifications:", data.notifications);
        setNotifications(data.notifications || []);
      } else {
        console.error(
          "Failed to fetch notifications:",
          await response.text()
        );
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    if (rhid === 7002) {
      // Fetch admin-created notifications
      fetchAdminNotifications();
    } else {
      // Fetch notifications for normal users
      fetchNotifications();
    }
  }, [rhid]);

  // Initialize Socket.IO with the RHID
  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   if (token) {
  //     try {
  //       const decodedToken = jwtDecode(token);
  //       setRhid(decodedToken?.RHID); // Dynamically set the RHID
  //     } catch (error) {
  //       console.error("Failed to decode token:", error);
  //     }
  //   }
  // }, []);

  // useEffect(() => {
  //   const socket = io(`${apiUrl}`, {
  //     query: { rhid }, // Pass RHID as a query parameter
  //   });

  //   socket.on("new-notification", (notification) => {
  //     setNotifications((prev) => [notification, ...prev]);
  //   });

  //   return () => socket.disconnect(); // Cleanup
  // }, [rhid]); // Ensure RHID is included as a dependency

  // const fetchNotifications = async () => {
  //   const token = localStorage.getItem("token");

  //   if (!token) {
  //     console.error("No token found");
  //     return;
  //   }

  //   const response = await fetch(`${apiUrl}/api/notifications`, {
  //     headers: { Authorization: `Bearer ${token}` },
  //   });

  //   if (response.ok) {
  //     const data = await response.json();
  //     setNotifications(data.notifications || []);
  //   } else {
  //     console.error("Failed to fetch notifications:", await response.text());
  //   }
  // };

  // useEffect(() => {
  //   fetchNotifications();
  // }, []);

  // const fetchNotifications = async () => {
  //   const token = localStorage.getItem("token");

  //   if (!token) {
  //     console.error("No token found");
  //     return;
  //   }

  //   const response = await fetch(`${apiUrl}/api/notifications`, {
  //     headers: { Authorization: `Bearer ${token}` },
  //   });

  //   if (response.ok) {
  //     const data = await response.json();
  //     setNotifications(data.notifications || []);
  //   } else {
  //     console.error("Failed to fetch notifications:", await response.text());
  //   }
  // };

  // useEffect(() => {
  //   fetchNotifications();
  // }, []);

  const handleCreateNotification = async () => {
    if (!imgbbApiKey) {
      console.error(
        "ImgBB API Key is not defined. Please check your .env file."
      );
      alert("Image upload failed. Missing API key.");
      return;
    }

    try {
      let imageUrl = ""; // Default value if no image is uploaded

      // Check if an image file is provided
      if (imageFile) {
        // Upload the image to ImgBB
        const formData = new FormData();
        formData.append("image", imageFile);

        const imgbbResponse = await axios.post(
          `https://api.imgbb.com/1/upload?key=${imgbbApiKey}`,
          formData
        );

        if (imgbbResponse.status === 200) {
          imageUrl = imgbbResponse.data.data.url; // Get the image URL
        } else {
          alert("Failed to upload image. Please try again.");
          return;
        }
      }

      const token = localStorage.getItem("token");
      if (!token) {
        alert("Please log in to create a notification.");
        return;
      }

      // Update newNotification with the optional image URL
      const notificationData = { ...newNotification, image_url: imageUrl };

      const response = await fetch(`${apiUrl}/api/push-notifications`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(notificationData),
      });

      if (response.ok) {
        alert("Notification created and sent to target users!");
        setIsCreateModalVisible(false); // Close modal
        setNewNotification({}); // Reset notification fields
      } else {
        console.error("Failed to create notification:", await response.text());
      }
    } catch (error) {
      console.error("Error creating notification or uploading image:", error);
    }
  };

  const handlePushNotification = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        alert("Please log in to send a notification.");
        return;
      }

      // Include all fields, using defaults if any are undefined
      const payload = {
        city_filter: selectedNotification.CITY_FILTER || "",
        gender_filter: selectedNotification.GENDER_FILTER || "",
        age_min: selectedNotification.AGE_MIN || null,
        age_max: selectedNotification.AGE_MAX || null,
        company_filter: selectedNotification.COMPANY_FILTER || "",
        subject: selectedNotification.SUBJECT || "",
        body: selectedNotification.BODY || "",
      };

      const response = await fetch(
        `${apiUrl}/api/update-push-notifications/${selectedNotification.ID_NOTIF}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload), // Send updated filters
        }
      );

      if (response.ok) {
        // setNotifications((prev) =>
        //   prev.filter(
        //     (notif) => notif.ID_NOTIF !== selectedNotification.ID_NOTIF
        //   )
        // ); // Remove the notification from the list
        fetchNotifications();
        alert("Notification updated successfully!");
        setIsModalVisible(false); // Close modal
      } else {
        console.error("Failed to push notification:", await response.text());
        alert("Failed to push notification.");
      }
    } catch (error) {
      console.error("Error pushing notification:", error);
      alert("An error occurred while pushing the notification.");
    }
  };

  const openModal = async (notification) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        alert("Please log in to view notification details.");
        return;
      }

      const response = await fetch(
        `${apiUrl}/api/v2/notifications/${notification.ID_NOTIF}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSelectedNotification(data.data); // Load detailed notification data
        setOriginalNotification(data.data); // Store the original data
        setIsModalVisible(true);
      } else {
        console.error(
          "Failed to fetch notification details:",
          await response.text()
        );
        alert("Failed to fetch notification details.");
      }
    } catch (error) {
      console.error("Error fetching notification details:", error);
      alert("An error occurred while fetching notification details.");
    }
  };

  const handleFilterChange = (field, value) => {
    setSelectedNotification((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <div>
      <CommonHeaderWithMenu title={t("Notification")} />

      <div className="p-4">
        {rhid === 7002 ? (
          <Button
            type="primary"
            onClick={() => setIsCreateModalVisible(true)}
            className="mb-4"
          >
            Create Notification
          </Button>
        ) : (
          <></>
        )}

        {/* Notifications List */}
        <div className="mb-16">
          {notifications.length > 0 ? (
            notifications.map((notification) => (
              <div key={notification.ID_NOTIF} className="mb-6">
                <div className="flex items-start justify-between">
                  {/* Notification Icon/Thumbnail */}
                  <div className="flex items-start">
                    {notification.IMAGE_URL ? (
                      <img
                        src={notification.IMAGE_URL}
                        alt="Notification Thumbnail"
                        className="w-12 h-12 rounded-full object-cover"
                      />
                    ) : (
                      <div className="w-12 h-12 flex items-center justify-center rounded-full bg-[#F1F6E7]">
                        <img
                          src={
                            iconMap[notification.TYPE?.toLowerCase()] || speaker
                          }
                          alt={`${notification.TYPE} icon`}
                          className="w-6 h-6"
                        />
                      </div>
                    )}
                  </div>

                  {/* Notification Details */}
                  <div className="flex-1 ml-4">
                    <h3 className="text-gray-800 font-semibold text-sm">
                      {notification.SUBJECT}
                    </h3>
                    <p className="text-gray-500 text-xs">{notification.BODY}</p>
                    <p className="text-[#8AB53E] text-xs mt-1">
                      {new Date(notification.DT_INSERTED).toLocaleString()}
                    </p>
                  </div>

                  {/* More Options */}
                  <div className="ml-4">
                    <FiMoreVertical
                      className="text-gray-400 cursor-pointer"
                      onClick={() => openModal(notification)}
                    />
                  </div>
                </div>

                {/* Separator */}
                <hr className="mt-4 border-[#D9D9D9]" />
              </div>
            ))
          ) : (
            <div className="text-center text-red-500 font-semibold">
              {t("No notifications available.")}.
            </div>
          )}
        </div>
      </div>

      {/* Modal */}
      <Modal
        title="Update Notification"
        visible={isModalVisible}
        onOk={handlePushNotification}
        onCancel={() => setIsModalVisible(false)}
        okText="Update"
        cancelText="Cancel"
      >
        {selectedNotification && (
          <div className="space-y-4">
            <Input
              placeholder="Subject"
              value={selectedNotification.SUBJECT}
              onChange={(e) => handleFilterChange("SUBJECT", e.target.value)}
            />
            <Input.TextArea
              placeholder="Body"
              value={selectedNotification.BODY}
              onChange={(e) => handleFilterChange("BODY", e.target.value)}
            />
            <Input
              placeholder="City Filter"
              value={selectedNotification.CITY_FILTER}
              onChange={(e) =>
                handleFilterChange("CITY_FILTER", e.target.value)
              }
            />
            <Input
              placeholder="Gender Filter"
              value={selectedNotification.GENDER_FILTER}
              onChange={(e) =>
                handleFilterChange("GENDER_FILTER", e.target.value)
              }
            />
            <Input
              placeholder="Age Min"
              type="number"
              value={selectedNotification.AGE_MIN}
              onChange={(e) => handleFilterChange("AGE_MIN", e.target.value)}
            />
            <Input
              placeholder="Age Max"
              type="number"
              value={selectedNotification.AGE_MAX}
              onChange={(e) => handleFilterChange("AGE_MAX", e.target.value)}
            />
            <Input
              placeholder="Company Filter"
              value={selectedNotification.COMPANY_FILTER}
              onChange={(e) =>
                handleFilterChange("COMPANY_FILTER", e.target.value)
              }
            />
          </div>
        )}
      </Modal>
      {/* Create Notification Modal */}
      <Modal
        title="Create Notification"
        visible={isCreateModalVisible}
        onOk={handleCreateNotification}
        onCancel={() => setIsCreateModalVisible(false)}
        okText="Push"
        cancelText="Cancel"
      >
        <Select
          mode="Type"
          placeholder="Select Types"
          value={newNotification.type}
          onChange={(value) =>
            // setNewNotification({ ...newNotification, target_users: value })
            setNewNotification({ ...newNotification, type: value })
          }
          className="w-full"
        >
          <Option value="A-About">A-About</Option>
          <Option value="B-Privacy-Policy">B-Privacy Policy</Option>
          <Option value="D-Contacts">D-Contacts</Option>
          <Option value="F-FAQs">F-FAQs</Option>
          <Option value="G-Legal-Warning">G-Legal Warning</Option>
          <Option value="I-Contact-Us">I-Contact Us</Option>
        </Select>
        {/* <Input placeholder="" className="mb-2" /> */}
        <Input
          placeholder="Subject"
          value={newNotification.subject}
          onChange={(e) =>
            setNewNotification({ ...newNotification, subject: e.target.value })
          }
          className="mb-2 mt-2"
        />
        <Input.TextArea
          placeholder="Body"
          value={newNotification.body}
          onChange={(e) =>
            setNewNotification({ ...newNotification, body: e.target.value })
          }
          className="mb-2"
        />
        <Input
          type="file"
          accept="image/*"
          onChange={(e) => setImageFile(e.target.files[0])} // Save the selected file
          className="mb-2"
        />
        {/* <Input
          placeholder="Image URL"
          value={newNotification.image_url}
          onChange={(e) =>
            setNewNotification({
              ...newNotification,
              image_url: e.target.value,
            })
          }
          className="mb-2"
        /> */}
        <Input
          type="datetime-local"
          placeholder="Target Date"
          value={newNotification.target_date}
          onChange={(e) =>
            setNewNotification({
              ...newNotification,
              target_date: e.target.value,
            })
          }
          className="mb-2"
        />
        <Select
          mode="multiple" // Enable multiple selections
          placeholder="Select Gender(s)"
          value={
            newNotification.gender_filter
              ? newNotification.gender_filter.split(",")
              : []
          } // Convert to array for display
          onChange={(value) =>
            setNewNotification({
              ...newNotification,
              gender_filter: value.join(","), // Convert array to comma-separated string for backend
            })
          }
          className="mb-2 w-full"
        >
          <Option value="Male">Male</Option>
          <Option value="Female">Female</Option>
          <Option value="Other">Other</Option>
        </Select>

        <Input
          placeholder="Age Min"
          type="number"
          value={newNotification.age_min}
          onChange={(e) =>
            setNewNotification({ ...newNotification, age_min: e.target.value })
          }
          className="mb-2"
        />
        <Input
          placeholder="Age Max"
          type="number"
          value={newNotification.age_max}
          onChange={(e) =>
            setNewNotification({ ...newNotification, age_max: e.target.value })
          }
          className="mb-2"
        />
        <Input
          placeholder="City Filter"
          value={newNotification.city_filter}
          onChange={(e) =>
            setNewNotification({
              ...newNotification,
              city_filter: e.target.value,
            })
          }
          className="mb-2"
        />
        <Input
          placeholder="Company Filter"
          value={newNotification.company_filter}
          onChange={(e) =>
            setNewNotification({
              ...newNotification,
              company_filter: e.target.value,
            })
          }
          className="mb-2"
        />
        {/* <Select
          mode="tags"
          placeholder="Target Users (RHIDs)"
          value={newNotification.target_users}
          onChange={(value) =>
            setNewNotification({ ...newNotification, target_users: value })
          }
          className="w-full"
        >
          <Option value="7098">Employee RHID 7098</Option>
          <Option value="7078">Employee RHID 7078</Option>
        </Select> */}
      </Modal>

      <FooterNavigation />
    </div>
  );
};

export default Notification;
// import React, { useEffect, useState } from "react";
// import { io } from "socket.io-client";
// import { Modal, Input } from "antd";
// import { FiMoreVertical } from "react-icons/fi";
// import FooterNavigation from "../FooterNavigation";
// import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";
// import { useTranslation } from "react-i18next";

// const socket = io("http://localhost:5000", {
//   query: { rhid: localStorage.getItem("rhid") }, // Pass RHID to the server
// });

// const Notification = () => {
//   const [notifications, setNotifications] = useState([]);
//   const [selectedNotification, setSelectedNotification] = useState(null);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const { t } = useTranslation();
//   const apiUrl = process.env.REACT_APP_BASE_URL;

//   useEffect(() => {
//     // Fetch initial notifications
//     const fetchNotifications = async () => {
//       const token = localStorage.getItem("token");
//       if (!token) return;

//       const response = await fetch(`${apiUrl}/api/notifications`, {
//         headers: { Authorization: `Bearer ${token}` },
//       });
//       const data = await response.json();
//       setNotifications(data.notifications || []);
//     };

//     fetchNotifications();

//     // Listen for real-time notifications
//     socket.on("new-notification", (notification) => {
//       setNotifications((prev) => [notification, ...prev]);
//     });

//     return () => {
//       socket.disconnect();
//     };
//   }, [apiUrl]);

//   const handlePushNotification = async () => {
//     const token = localStorage.getItem("token");
//     if (!token || !selectedNotification) return;

//     const response = await fetch(
//       `${apiUrl}/api/update-push-notifications/${selectedNotification.ID_NOTIF}`,
//       {
//         method: "PUT",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify(selectedNotification),
//       }
//     );

//     if (response.ok) {
//       setNotifications((prev) =>
//         prev.filter((notif) => notif.ID_NOTIF !== selectedNotification.ID_NOTIF)
//       );
//       setIsModalVisible(false);
//       alert("Notification updated and pushed successfully!");
//     } else {
//       console.error("Failed to push notification:", await response.text());
//     }
//   };

//   const openModal = async (notification) => {
//     const token = localStorage.getItem("token");
//     if (!token) return;

//     const response = await fetch(
//       `${apiUrl}/api/v2/notifications/${notification.ID_NOTIF}`,
//       {
//         headers: { Authorization: `Bearer ${token}` },
//       }
//     );
//     const data = await response.json();
//     setSelectedNotification(data.data);
//     setIsModalVisible(true);
//   };

//   const handleFilterChange = (field, value) => {
//     setSelectedNotification((prev) => ({
//       ...prev,
//       [field]: value,
//     }));
//   };

//   return (
//     <div>
//       <CommonHeaderWithMenu title={t("Notification")} />
//       <div className="p-4">
//         {notifications.length > 0 ? (
//           notifications.map((notification) => (
//             <div key={notification.ID_NOTIF} className="mb-6">
//               <div className="flex items-start justify-between">
//                 <div className="flex-1 ml-4">
//                   <h3 className="text-gray-800 font-semibold text-sm">
//                     {notification.SUBJECT}
//                   </h3>
//                   <p className="text-gray-500 text-xs">{notification.BODY}</p>
//                 </div>
//                 <div className="ml-4">
//                   <FiMoreVertical
//                     className="text-gray-400 cursor-pointer"
//                     onClick={() => openModal(notification)}
//                   />
//                 </div>
//               </div>
//             </div>
//           ))
//         ) : (
//           <div className="text-center text-red-500 font-semibold">
//             {t("No notifications available.")}.
//           </div>
//         )}
//       </div>
//       <Modal
//         title="Edit Notification Filters"
//         visible={isModalVisible}
//         onOk={handlePushNotification}
//         onCancel={() => setIsModalVisible(false)}
//         okText="Push"
//         cancelText="Cancel"
//       >
//         {selectedNotification && (
//           <div>
//             <Input
//               placeholder="Subject"
//               value={selectedNotification.SUBJECT}
//               onChange={(e) => handleFilterChange("SUBJECT", e.target.value)}
//             />
//             <Input.TextArea
//               placeholder="Body"
//               value={selectedNotification.BODY}
//               onChange={(e) => handleFilterChange("BODY", e.target.value)}
//             />
//           </div>
//         )}
//       </Modal>
//       <FooterNavigation />
//     </div>
//   );
// };

// export default Notification;
