import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

const AdaptabilityForm = () => {
  const [formData, setFormData] = useState({
    EMPRESA: "",
    DT_ADMISSAO: "",
    DT_INI: "",
    DT_FIM: "",
    TP_OCORRENCIA: "",
  });

  const [existingEmpresas, setExistingEmpresas] = useState([]);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_URL;

  // Fetch existing EMPRESA values
  useEffect(() => {
    const fetchExistingEmpresas = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${apiUrl}/api/admin/adaptabilities/empresas`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setExistingEmpresas(response.data || []); // Populate existing EMPRESA values
      } catch (error) {
        console.error("Error fetching existing EMPRESA values:", error);
      }
    };

    fetchExistingEmpresas();
  }, [apiUrl]);

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate uniqueness of EMPRESA
    if (
      existingEmpresas.some(
        (empresa) =>
          empresa.trim().toLowerCase() === formData.EMPRESA.trim().toLowerCase()
      )
    ) {
      toast.error("EMPRESA must be unique. Please provide a different value.");
      return; // Stop submission
    }

    // Submit data
    try {
      const token = localStorage.getItem("token");
      const payload = {
        empresa: formData.EMPRESA || "DEMO03",
        dtAdmissao: formData.DT_ADMISSAO,
        dtIniDet: formData.DT_INI,
        dtFimDet: formData.DT_FIM,
        tpOcorrencia: formData.TP_OCORRENCIA,
      };

      await axios.post(`${apiUrl}/api/admin/adaptabilities`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success("Adaptability added successfully!");
      navigate("/absenses");
    } catch (error) {
      console.error("Error adding adaptability:", error);
      toast.error("Failed to add adaptability.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3 className="font-medium mb-4">Adaptability Details</h3>
      <div>
        <label className="block font-medium">EMPRESA</label>
        <input
          type="text"
          name="EMPRESA"
          value={formData.EMPRESA}
          onChange={handleChange}
          className="w-full border border-gray-300 p-2 rounded"
        />
      </div>
      <div>
        <label className="block font-medium">Admission Date</label>
        <input
          type="date"
          name="DT_ADMISSAO"
          value={formData.DT_ADMISSAO}
          onChange={handleChange}
          className="w-full border border-gray-300 p-2 rounded"
        />
      </div>
      <div>
        <label className="block font-medium">Start Date & Time</label>
        <input
          type="datetime-local"
          name="DT_INI"
          value={formData.DT_INI}
          onChange={handleChange}
          className="w-full border border-gray-300 p-2 rounded"
        />
      </div>
      <div>
        <label className="block font-medium">End Date & Time</label>
        <input
          type="datetime-local"
          name="DT_FIM"
          value={formData.DT_FIM}
          onChange={handleChange}
          className="w-full border border-gray-300 p-2 rounded"
        />
      </div>
      <div>
        <label className="block font-medium">Adapt Type</label>
        <input
          type="text"
          name="TP_OCORRENCIA"
          value={formData.TP_OCORRENCIA}
          onChange={handleChange}
          className="w-full border border-gray-300 p-2 rounded"
        />
      </div>
      <div className="flex justify-between gap-3">
        <div className="mt-4">
          <Link to="/absenses">
            <button
              type="submit"
              className="bg-[#b53e3e] text-white px-4 py-2 rounded"
            >
              cancel
            </button>
          </Link>
        </div>
        <button
          type="submit"
          className="bg-[#8AB53E] text-white px-4 py-2 rounded mt-4"
        >
          Submit Adaptability
        </button>
      </div>
    </form>
  );
};

export default AdaptabilityForm;
